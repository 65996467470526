// Types
import type { Id } from '@luxottica/rtr-viewer';

import type { ApplicativeZoom, InitInput } from '@/types';

export const init = (initInput: InitInput) => {
    import('@/lazy_load/init').then(({ initAsync }) => {
        initAsync(initInput);
    });
};

// ACTIONS
export const dispose = () => {
    if (!isInitialized()) return;

    import('@/stores/ui').then(({ useUiStore }) => {
        const uiStore = useUiStore();
        uiStore.forceUnmount = true;
    });
};

// GETTERS
export const getVersion = () => {
    return VITE_LIB_VERSION;
};

export const isIdAvailable = async (id: Id) => {
    return import('@luxottica/rtr-viewer').then(
        ({ isIdAvailable: isRtrIdAvailable }) =>
            isRtrIdAvailable(id, { env: RTR_ENVIRONMENT })
    );
};

export const isInitialized = async () => {
    return import('@luxottica/rtr-viewer').then(
        ({ isInitialized: isRtrInitialized }) => isRtrInitialized()
    );
};

export const isSupported = async () => {
    return import('@luxottica/rtr-viewer').then(
        ({ isSupported: isRtrSupported }) => isRtrSupported()
    );
};

// SETTERS
export const setApplicativeZoom = (zoom: ApplicativeZoom) => {
    if (!(isInitialized() && zoom)) return;

    import('@/stores/zoom').then(({ useZoomStore }) => {
        const zoomStore = useZoomStore();
        zoomStore.setApplicativeZoom(zoom);
    });
};

export const setId = (id: Id) => {
    import('@/lazy_load/viewer').then(({ setIdAsync }) => {
        setIdAsync(id);
    });
};
